import { ref, watch } from 'vue'
import stringToAnchor from '../../../utilities/transformers/string-to-anchor.js';

export default {
    inheritAttrs: false,
    props: {
      items: {
        type: Object,
        required: true
      }
    },
    methods: {
      getAnchorTag (string) {
          return stringToAnchor(string);
      }
    },
    setup(props) {
        const localItems = ref(props.items);
        watch(() => props.items, (newItems) => {
            localItems.value = newItems;
        });
        return {
            localItems
        }
    }
}
