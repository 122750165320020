import { createApp, ref } from 'vue'
import App from './app.vue'
import router from './routing/router.js'
import components from './components'
import i18nService from './services/i18n-service.js'
import useBrowser from './utilities/use-browser.js'
import VueGtag from "vue-gtag-next";
import environment from '../.configs/app/environment.js'
import { trackRouter } from "vue-gtag-next";


import './styles/main.scss';

async function main() {

    const app = createApp(App)

    // -- Register components
    for (const component in components) {
        app.component(component, components[component])
    }


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lang = urlParams.get('language');

    const initialLanguage = lang || localStorage.getItem('language') || navigator.language.split('-')[0];
    if (!localStorage.getItem('language')) {
        localStorage.setItem('language', initialLanguage);
    }

    const language = ref(initialLanguage);
    const languageData = ref(await i18nService.index(language.value));

    app.provide('$language', language);
    app.provide('$languageData', languageData);

    const {
        browserInfo,
        supportsExtension
    } = useBrowser()

    const extensionLabel = ref()
    const commonLanguageData = languageData.value.common
    if (supportsExtension) {
        if (browserInfo.name === 'Chromium' || browserInfo.name === 'Chrome') {
            extensionLabel.value = commonLanguageData.addToDefBrowser;
        } else if (browserInfo.name === 'Microsoft Edge') {
            extensionLabel.value = commonLanguageData.addToBrowser.replace('{{browser}}', 'Edge');
        }
    } else {
        extensionLabel.value = commonLanguageData.getChromeExtension
    }

    app.provide('$extensionLabel', extensionLabel);

    app.provide('$tokenEx', TokenEx);
    app.use(router)

    app.use(VueGtag, {
        property: { id: environment.app.gaId },
    })
    trackRouter(router, {
        useScreenview: true
    })

    app.mount('#app')



}

main();


