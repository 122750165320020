<template>
    <tmpl-default>
        <router-view />
    </tmpl-default>
</template>

<!-- <script src="./app.js"></script> -->

<style lang="scss">
@import './styles/main.scss';
</style>
