<template>
	<div class="tmpl-default">
		<mlc-navigation class="tmpl-default__navigation">
			<template v-slot:logo>
				<a href="/" area-label="Home" title="Home">
					<atm-img :src="navigation.logo.src" :alt="navigation.logo.alt"
						class="cursor-pointer h-[24px] mx-auto sm:mx-0" />
					<span class="tmpl-default__navigation__crawler-only">Home</span>
				</a>
			</template>
			<template v-slot:menu>
				<mlc-list class="tmpl-default__navigation-list sm:block hidden">
					<button class="textNeutral2" v-for="(menu, menuIndex) in navigation.menu" :key="menuIndex">
						<router-link :to="{ name: menu.name }" @click.native="goToHash(menu)" tabindex="-1">{{
							menu.copy }}</router-link>
					</button>
				</mlc-list>
			</template>
			<template v-slot:action>
				<button v-if="route.path !== '/installed'" class="button-red"
					@click="href(navigation.action.href, true, true)">
					<div class="button-2">
						{{ navigation.action.copy }}
					</div>
				</button>
			</template>
		</mlc-navigation>
		<div v-bind="$attrs" class="tmpl-default__body">
			<slot />
		</div>
		<div v-if="route.path !== '/installed'" class="tmpl-default__footer-wrapper">
			<Footer :footerSections="footerSections" />
		</div>
	</div>
</template>

<script src="./tmpl-default.js" />

<style lang="scss" src="./tmpl-default.scss"></style>
