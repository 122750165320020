// https://github.com/bowser-js/bowser
import Bowser from 'bowser'
import { inject, computed, ref, onMounted } from 'vue'
import i18nService from '../services/i18n-service'

const userAgent = window.navigator.userAgent
const browser = Bowser.getParser(userAgent)
const browserInfo = browser.getBrowser()
const isMobile = browser.is('mobile')
const isTablet = browser.is('tablet')
const isTouch = isMobile || isTablet

const supportsExtension = browser.satisfies({
    desktop: {
        chrome: '>80',
        chromium: '>80',
        // firefox: '>72',
        edge: '>83',
    },
})

// extension CTA
const useComputedExtensionLabel = async () => {
    const computedExtensionLabel = ref('')

    const updateLabel = async () => {
        const languageData = await i18nService.show('common', inject('$language').value);

        if (supportsExtension) {
            if (browserInfo.name === 'Chromium') {
                computedExtensionLabel.value = languageData.addToBrowser.replace('{{browser}}', 'Chrome');
            } else {
                computedExtensionLabel.value = languageData.addToBrowser.replace('{{browser}}', browserInfo.name);
            }
        } else {
            computedExtensionLabel.value = languageData.tryExtension;
        }
    }

    onMounted(updateLabel)

    return computedExtensionLabel
}

export default () => {
    return {
        userAgent,
        browserInfo,
        isMobile,
        isTablet,
        isTouch,
        supportsExtension,
        useComputedExtensionLabel,
    }
}
