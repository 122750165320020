<template>
    <div v-bind="$attrs" class="mlc-footer">
        <mlc-grid class="mlc-footer__grid">
            <atm-item class="mlc-footer__grid-left">
                <slot name="left"></slot>
            </atm-item>
            <atm-item class="mlc-footer__grid-middle">
                <slot name="middle"></slot>
            </atm-item>
            <atm-item class="mlc-footer__grid-right">
                <slot name="right"></slot>
            </atm-item>
        </mlc-grid>
    </div>
</template>

<script src="./mlc-footer.js"/>

<style lang="scss" src="./mlc-footer.scss"></style>