import BaseAPI from './base-api-store';

export default class i18nAPI extends BaseAPI {
  static async show(namespace, language) {
    try {
      const response = await BaseAPI.fetch(`/i18n/${namespace}/${language}`, { method: 'get' });
      if (!response.ok) {
        throw new Error(`Failed to get i18n data for namespace: ${namespace} and language: ${language}`);
      }
      const responseData = await response.json();
      return responseData.data;
    } catch (error) {
      console.error(`Error getting i18n data for namespace: ${namespace} and language: ${language}`);
      throw error;
    }
  }

}
