import AccordionItem from '../../molecules/mlc-accordion-item/mlc-accordion-item.vue';


export default {
    data() {
        return {
            openItemId: null,
        };
    },
    components: {
        AccordionItem
    },
    props: {
        items: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleToggle(itemId) {
            this.openItemId = this.openItemId === itemId ? null : itemId;
            this.$emit('toggleAccordion')
        },
    },
}