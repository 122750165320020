export default {
  name: 'FAQ - Jauntr',
  path: '/faq',
  children: [
    {
      component: () => import('./pg-faq.vue'),
      name: 'faq-general',
      path: 'general',
      meta: {
        accessControl: { public: true },
        title: 'General FAQ - Jauntr',
        menuItem: 'general',
      },
    },
    {
      component: () => import('./pg-faq.vue'),
      name: 'faq-bookings',
      path: 'bookings',
      meta: {
        accessControl: { public: true },
        title: 'Bookings FAQ - Jauntr',
        menuItem: 'bookings',
      },
    },
    {
      component: () => import('./pg-faq.vue'),
      name: 'faq-pricing',
      path: 'pricing',
      meta: {
        accessControl: { public: true },
        title: 'Pricing FAQ - Jauntr',
        menuItem: 'pricing',
      },
    },
    {
      component: () => import('./pg-faq.vue'),
      name: 'faq-offers',
      path: 'offers',
      meta: {
        accessControl: { public: true },
        title: 'Offers FAQ - Jauntr',
        menuItem: 'offers',
      },
    },
    {
      component: () => import('./pg-faq.vue'),
      name: 'faq-points',
      path: 'points',
      meta: {
        accessControl: { public: true },
        title: 'Points FAQ - Jauntr',
        menuItem: 'points',
      },
    },
  ],
}
