export default {
    component: () => import('./pg-home.vue'),
    meta: {
        accessControl: { public: true },
        title: 'Jauntr',
        ui: {
            // linkDisplayText: getLinkDisplayText({ route: 'home' }),
            showLinkInFooterColumn: 1,
            smoothAutoscroll: true,
        },
    },
    name: 'home',
    path: '/',
}