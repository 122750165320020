export default {
    component: () => import('./pg-installed.vue'),
    meta: {
        accessControl: { public: true },
        title: 'Installed - Jauntr',
        ui: {
            // linkDisplayText: getLinkDisplayText({ route: 'installedUs' }),
            // showLinkInFooterColumn: 1,
            // showLinkInHeader: true,
            // smoothAutoscroll: false,
        },
    },
    name: 'installed',
    path: '/installed',
}