import environment from "../../../../.configs/app/environment.js"


export default {
  props: {
    title: String,
    content: String,
    isOpen: Boolean
  },

  setup() {
    return {
      environment
    }
  }

}