import Footer from '../../molecules/pg-footer/pg-footer.vue';
import useBrowser from '../../../utilities/use-browser.js'
import { ref, provide, inject } from 'vue'
import environment from '../../../../.configs/app/environment.js';
import { useRoute } from 'vue-router';
import { useGtag } from "vue-gtag-next";

export default {
    components: { Footer },


    setup() {
        const { event } = useGtag()
        const route = useRoute()
        const showDialog = ref()

        const updateShowDialog = (val) => {
            showDialog.value = val
        }

        provide('showDialog', { showDialog, updateShowDialog })

        const extensionLabel = inject('$extensionLabel')

        const {
            isTouch,
        } = useBrowser()

        const href = (url, inNewTab , addToChromeBtn) => {


            if(addToChromeBtn) {
                event('click add to chrome button')
            }

            if (!isTouch) {
                if (inNewTab) {
                    window.open(url, '_blank');
                } else {
                    window.location.href = url;
                }

            } else {
                showDialog.value = true
            }
        }

        const goToHash = (item) => {
            if (item.hash) {
                location.hash = item.hash
            }
        }

        const languageData = inject('$languageData').value.common

        const navigation = ref({
            logo: {
                src: '/assets/images/jauntr-logo.svg',
                alt: languageData.jauntrLogoAlt,
                name: 'home'
            },
            menu: {
                howItWorks: {
                    copy: languageData.howItWorksNavButton,
                    name: 'how-it-works',
                },
                // about: {
                //     copy: languageData.aboutUsNavButton,
                //     name: 'about-us'
                // },
                points: {
                    copy: languageData.pointsNavButton,
                    name: 'points'
                },
                support: {
                    copy: languageData.faq,
                    name: 'faq-general'
                },
            },
            action: {
                copy: extensionLabel,
                href: environment.ext.url
            }
        })

        const footerSections = [
            {
                title: "Jauntr",
                separator: true,
                links: [
                    { text: languageData.homeFooterLink, name: "home" },
                    { text: languageData.howItWorksNavButton, name: 'how-it-works' },
                    { text: languageData.pointsNavButton, name: 'points' },
                    // { text: languageData.aboutUsNavButton, name: "about-us" },
                    // { text: languageData.supportNavButton, name: "support" },
                ],
            },
            {
                title: languageData.faq,
                separator: true,
                links: [
                    { text: languageData.faqGeneralFooterLink, name: "faq-general" },
                    { text: languageData.faqBookingsFooterLink, name: "faq-bookings" },
                    { text: languageData.faqFooterHeader, name: "faq-pricing" },
                //     { text: languageData.faqOffersFooterLink, name: "faq-offers" },
                //     { text: languageData.faqPointsFooterLink, name: "faq-points" },
                ],
            },
            {
                title: `© ${new Date().getFullYear()}`,
                separator: true,
                links: [
                    { text: languageData.termsFooterLink, name: "terms-and-conditions" },
                    { text: languageData.privacyPolicyFooterLink, name: "privacy-policy" },
                    { text: languageData.contactFooterLink, name: "faq-general" },
                ],
            },
            // {
            //     title: "Social Links",
            //     separator: true,
            //     links: [
            //         { text: "Facebook", },
            //         { text: "Twitter", },
            //         { text: "Instagram", },
            //     ],
            // },
        ]

        return {
            route,
            href,
            showDialog,
            goToHash,
            footerSections,
            navigation,
        }

    },
};
