import { ref, watch } from 'vue'

export default {
    inheritAttrs: false,
    data () {
        return {
          currentIndex:0,
          currentImage: this.data.slides.select.image,
        };
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods: {
        href(url) {
            window.location.href = url;
        },
        nextSlide() {
            this.currentIndex++;
            // if(this.currentIndex > Object.keys(this.data.slides).length - 1) {
            //     this.$router.push(this.data.links.finish);
            // }
            this.currentImage = this.data.slides[Object.keys(this.data.slides)[this.currentIndex]].image;

        },
        previousSlide() {
            this.currentIndex--;
            // if(this.currentIndex < 0) {
            //   this.$router.push(this.data.links.cancel);
            // }
            this.currentImage = this.data.slides[Object.keys(this.data.slides)[this.currentIndex]].image;

        }
        
    },
    setup(props) {
        const localData = ref(props.data);
        watch(() => props.data, (newData) => {
            localData.value = newData;
        });
        return {
            localData
        }
    }
}
